
import { mapGetters } from 'vuex'

export default {
  name: 'LoadingPublic',
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      loadingpublic: 'layout/loadingpublic',
      msgloadingpublic: 'layout/msgloadingpublic'
    })
  }
}
